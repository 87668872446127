/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import MDXLayout from "/builds/thiagovilla/thiagovilla.com/src/templates/blog";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "TAKE NOTES"), "\n", React.createElement(_components.p, null, "SHARE THEM"), "\n", React.createElement(_components.p, null, "definition of done"), "\n", React.createElement("div", {
    style: {
      color: "red"
    }
  }, "red"), "\n", React.createElement(_components.p, null, "action points / next steps"));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
